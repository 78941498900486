<template>
  <div>
    <v-select
      v-model="tags"
      :options="options"
      label="name"
      value="id"
      taggable
      multiple
      :create-option="(item) => ({ id: Math.random(), name: item })"
      @search="search"
      @option:created="created"
    />
  </div>
</template>

<script>
import VSelect from "vue-select";
import { search as tagSearch, store } from "@/api/person-tag.api";

export default {
  name: "person-tag-manager",
  props: ["initialTags", "personId", "value"],
  components: { VSelect },
  data() {
    return {
      tags: [],
      options: [],
    };
  },
  watch: {
    tags() {
      this.$emit("input", this.tags);
    },
    value() {
      this.tags = this.value;
    },
  },
  methods: {
    async search(search, loading) {
      loading(true);
      const { data } = await tagSearch({ name: search });
      this.options = data;
      loading(false);
    },
    async created({ id, name }) {
      const { data } = await store({
        organization_id: this.$auth.user().organization?.id,
        name,
      });
      this.tags = this.tags.filter((i) => i.id != id);
      this.tags = [...this.tags, data];
      return data;
    },
  },
  mounted() {
    this.tags = this.value;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.dark-layout {
  .vs__deselect {
    fill: rgba(210, 210, 210, 0.8);
  }
}
</style>