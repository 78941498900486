import axios from "@/libs/axios";

export async function search(query) {
    try {
        const { data: { data } } = await axios.get("/api/v1/person-tag/search", { params: query })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function store({ organization_id, name, }) {
    try {
        const { data: { data } } = await axios.post("/api/v1/person-tag", { organization_id, name })
        return { data }
    } catch (error) {
        return { error }
    }
}