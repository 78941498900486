var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('network-container',{attrs:{"loading":_vm.preLoading,"errors":_vm.errors},scopedSlots:_vm._u([{key:"shared",fn:function(){return [(_vm.personId)?_c('go-back',{attrs:{"to":{ name: 'person-show', params: { id: _vm.personId } },"title":_vm.pageTitle}}):_c('go-back',{attrs:{"title":_vm.$t('person.actions.create')}})]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-card',{attrs:{"title":_vm.$t('person.form.title-' + (_vm.personId ? 'update' : 'create'))}},[_c('validation-observer',{ref:"form"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.name'),"label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"name","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.reference'),"label-for":"reference"}},[_c('validation-provider',{attrs:{"name":"reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reference","name":"reference","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form.reference),callback:function ($$v) {_vm.$set(_vm.form, "reference", $$v)},expression:"form.reference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.date-of-birth'),"label-for":"date_of_birth"}},[_c('validation-provider',{attrs:{"name":"date_of_birth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_of_birth","state":errors.length > 0 ? false : null},model:{value:(_vm.form.date_of_birth),callback:function ($$v) {_vm.$set(_vm.form, "date_of_birth", $$v)},expression:"form.date_of_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.gender'),"label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.genders},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.tags')}},[_c('person-tag-manager',{model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}})],1)],1)],1),_c('div',[_c('h5',[_vm._v(_vm._s(_vm.$t('fields.dynamic_properties')))]),_vm._l((_vm.form.properties),function(prop,index){return _c('b-row',{key:prop.id},[_c('b-col',{attrs:{"sm":"12","md":"5"}},[_c('b-form-group',{staticClass:"flex-grow-1",attrs:{"label":_vm.$t('fields.key'),"label-for":("input-key-" + (prop.id))}},[_c('validation-provider',{attrs:{"name":"key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","id":("input-key-" + (prop.id)),"size":"sm"},model:{value:(prop['key']),callback:function ($$v) {_vm.$set(prop, 'key', $$v)},expression:"prop['key']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"10","sm":"10","md":"5"}},[_c('b-form-group',{staticClass:"flex-grow-1",attrs:{"label":_vm.$t('fields.value'),"label-for":("input-value-" + (prop.id))}},[_c('validation-provider',{attrs:{"name":"value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","id":("input-value-" + (prop.id)),"size":"sm"},model:{value:(prop['value']),callback:function ($$v) {_vm.$set(prop, 'value', $$v)},expression:"prop['value']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2","sm":"2"}},[_c('b-button',{staticClass:"mt-0 mt-md-2 mt-2",attrs:{"variant":"outline-danger","title":_vm.$t('common.actions.delete'),"size":"sm"},on:{"click":function (e) { return _vm.removeProperty(e, prop.id); }}},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t("common.actions.delete")))])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"btn-sm",attrs:{"variant":"outline-primary"},on:{"click":_vm.addNewProperty}},[_vm._v(" "+_vm._s(_vm.$t("common.actions.add"))+" ")])],1)],2),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"ml-auto",attrs:{"sm":"12","md":"4"}},[_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.loading)?_c('span',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.$t("general.loading")))]):_vm._e(),(!_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("person.actions." + (_vm.personId ? "update" : "create")))+" ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1),(_vm.personId)?_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-card',[_c('person-photo-manager',{attrs:{"editable":true,"initialPhotos":_vm.form.photos,"person-id":_vm.personId}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }