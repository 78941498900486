<template>
  <network-container :loading="preLoading" :errors="errors">
    <template v-slot:shared>
      <go-back
        v-if="personId"
        :to="{ name: 'person-show', params: { id: personId } }"
        :title="pageTitle"
      />
      <go-back v-else :title="$t('person.actions.create')" />
    </template>

    <b-row>
      <b-col sm="12" md="6">
        <b-card
          :title="$t('person.form.title-' + (personId ? 'update' : 'create'))"
        >
          <!-- form -->
          <validation-observer ref="form">
            <b-form class="mt-2">
              <b-row>
                <b-col md="6">
                  <!-- User Name -->
                  <b-form-group
                    :label="$t('fields.name')"
                    label-for="register-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="register-name"
                        v-model="form.name"
                        name="name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- User Reference -->
                  <b-form-group
                    :label="$t('fields.reference')"
                    label-for="reference"
                  >
                    <validation-provider #default="{ errors }" name="reference">
                      <b-form-input
                        id="reference"
                        v-model="form.reference"
                        name="reference"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- User Date of Birth -->
                  <b-form-group
                    :label="$t('fields.date-of-birth')"
                    label-for="date_of_birth"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date_of_birth"
                    >
                      <flat-pickr
                        id="date_of_birth"
                        v-model="form.date_of_birth"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- User Date of Birth -->
                  <b-form-group :label="$t('fields.gender')" label-for="gender">
                    <validation-provider
                      #default="{ errors }"
                      name="gender"
                      rules="required"
                    >
                      <b-form-select v-model="form.gender" :options="genders" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group :label="$t('fields.tags')">
                    <person-tag-manager v-model="form.tags" />
                  </b-form-group>
                </b-col>
              </b-row>
              <div>
                <h5>{{ $t('fields.dynamic_properties') }}</h5>
                <!-- REPEATER -->

                <b-row v-for="(prop, index) in form.properties" :key="prop.id">
                  <b-col sm="12" md="5">
                    <b-form-group
                      :label="$t('fields.key')"
                      class="flex-grow-1"
                      :label-for="`input-key-${prop.id}`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="key"
                        rules="required"
                      >
                        <b-form-input
                          v-model="prop['key']"
                          type="text"
                          :id="`input-key-${prop.id}`"
                          size="sm"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="10" sm="10" md="5">
                    <b-form-group
                      :label="$t('fields.value')"
                      class="flex-grow-1"
                      :label-for="`input-value-${prop.id}`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="value"
                        rules="required"
                      >
                        <b-form-input
                          v-model="prop['value']"
                          type="text"
                          :id="`input-value-${prop.id}`"
                          size="sm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2" sm="2">
                    <b-button
                      variant="outline-danger"
                      class="mt-0 mt-md-2 mt-2"
                      @click="(e) => removeProperty(e, prop.id)"
                      :title="$t('common.actions.delete')"
                      size="sm"
                    >
                      <feather-icon icon="XIcon" />
                      <span class="sr-only">{{
                        $t("common.actions.delete")
                      }}</span>
                    </b-button>
                  </b-col>

                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>

                <b-col class="d-flex justify-content-end">
                  <b-button
                    variant="outline-primary"
                    class="btn-sm"
                    @click="addNewProperty"
                  >
                    {{ $t("common.actions.add") }}
                  </b-button>
                </b-col>
              </div>
              <b-row class="mt-2">
                <b-col sm="12" md="4" class="ml-auto">
                  <b-button
                    variant="primary"
                    block
                    type="submit"
                    @click.prevent="validateForm"
                    :disabled="loading"
                  >
                    <b-spinner small v-if="loading" />
                    <span class="sr-only" v-if="loading">
                      {{ $t("general.loading") }}</span
                    >
                    <span v-if="!loading">
                      {{
                        $t("person.actions." + (personId ? "update" : "create"))
                      }}
                    </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col sm="12" md="6" v-if="personId">
        <b-card>
          <person-photo-manager
            :editable="true"
            :initialPhotos="form.photos"
            :person-id="personId"
          />
        </b-card>
      </b-col>
    </b-row>
  </network-container>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { store, update, show } from "@/api/people.api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PersonPhotoManager from "@/components/Person/PersonPhotoManager.vue";
import GoBack from "@/components/Common/GoBack.vue";
import PersonTagManager from "@/components/PersonTagManager.vue";
import flatPickr from "vue-flatpickr-component";
import NetworkContainer from "@/components/NetworkContainer.vue";
import moment from "moment"
export default {
  name: "PeopleForm",
  components: {
    FeatherIcon,
    ValidationProvider,
    ValidationObserver,
    PersonPhotoManager,
    PersonTagManager,
    GoBack,
    flatPickr,
    NetworkContainer,
  },
  data() {
    return {
      preLoading: true,
      loading: false,
      errors: { notFound: false, serverError: false },
      form: {
        name: null,
        reference: null,
        properties: [],
        organization_id: null,
        gender: "male",
        date_of_birth: null,
        photos: [],
        tags: [],
      },
      genders: [
        { text: this.$t("person.gender.male"), value: "male", default: true },
        { text: this.$t("person.gender.female"), value: "female" },
        { text: this.$t("person.gender.other"), value: "other" },
      ],
      pageTitle: "Create Person",
      required,
    };
  },
  computed: {
    personId() {
      return this.$route.params?.id;
    },
  },
  watch: {
    personId() {
      if (this.personId) {
        this.fetchData();
      } else {
        this.preLoading = false;
      }
    },
  },
  methods: {
    async fetchData() {
      this.preLoading = true;

      const { data, error } = await show(this.personId);

      if (data) {
        Object.keys(this.form).map((key) => {
          this.form[key] = data[key];
        });
        this.form.date_of_birth = data.date_of_birth ? moment.unix(data.date_of_birth).format('YYYY-MM-DD') : null;
        this.pageTitle = data.name;
      } else {
        this.errors.notFound = error.status == 404;
        this.errors.serverError = error.status == 500;
      }

      this.preLoading = false;
    },
    validateForm() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) return;
        this.persist();
      });
    },
    async persist() {
      this.loading = true;
      this.form.organization_id = this.$auth.user().organization?.id;
      const payload = { ...this.form, tags: this.form.tags.map((t) => t.id) };

      const { data, error } = this.personId
        ? await update(this.personId, payload)
        : await store(payload);
      this.loading = false;
      if (error) {

        // default toast title
        let msg = {
          title: error.title,
          text: null
        }

        if (error.status === 460 && this.$te(error.title)) {
          msg.title = this.$t(`${error.title}_title`)
          msg.text = this.$t(error.title)
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg.title,
            text: msg.text,
            icon: "XIcon",
            variant: "danger",
          },
        });

        this.$refs.form.setErrors(error.invalid_params);
      } else if (data) {
        this.loading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t(
              this.personId
                ? "person.form.success-update"
                : "person.form.success-create"
            ),
            icon: "CheckIcon",
            variant: "success",
          },
        });
        this.$router.push({
          name: this.personId ? "person-show" : "person-edit",
          params: { id: this.personId ?? data.id },
        });
      }
    },
    addNewProperty(event) {
      event.preventDefault();
      this.form.properties.push({ id: Math.random(), key: "", value: "" });
    },
    removeProperty(event, id) {
      event.preventDefault();
      this.form.properties = this.form.properties.filter((e) => e.id !== id);
    },
  },
  mounted() {
    if (this.personId) {
      this.fetchData();
    } else {
      this.preLoading = false;
    }
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>